
// Froda Color Scheme
$pink_100: #fff1f4;
$pink_300: #ff92a4;
$pink_500: #eb6087;
$pink_700: #af5073;
$green_300: #bcd3ad;
$green_500: #008370;
$green_700: #265953;
$yellow_300: #ecce7e;
$yellow_500: #e9ac26;
$yellow_700: #85775f;
$gray_100: #f3f3f3;
$gray_300: #babcbd;
$gray_400: #929292;
$gray_500: #7c7d7e;
$gray_700: #3a3836;

$color_primary: #fd5987;
$size_h3: 28px;
$color_notification: #fffde4;
$color_notification_border: #fdedc5;
$color_affirmative: #219653;
$color_destructive: #EB5757;
$color_primary_light: #FF92A4;

$c-head-1: #309B8C;
$c-head-2: #016A71;
$color_gradient: linear-gradient(149.85deg, $c-head-1 14.94%, $c-head-2 75.93%);

$font_header: 'Raleway';
$font_body: 'Roboto';


$header_fg: white;
$header_bg: $green_500;


$secondary: $pink_500;
$primary: $green_500;
$text: #555;
$text_light: #777;
$box_bg: #f3f3f3;

$button_primary_bg: $pink_500;
$button_primary_bg_hl: $pink_700;
$button_primary_fg: white;
$button_bg: #ddd;
$button_bg_hl: #ccc;
$button_fg: $text;

$input-border-color: #c2c2c2;
$input-border: 1px solid $input-border-color;

$head_bg: #f3f3f3;


$button_light: lighten($button_bg, 20%);

$redzone: rgb(143, 72, 72);
$redzone: $pink_700;

$input-radius: 4px;

$error: $pink_500;

$break-small: 480px;
$break-large: 992px;
$break-super-large: 1600px;
$box-shadow: 4px 4px 25px rgba(0,0,0,0.20);

@mixin device($media...) {
	@each $medium in $media {
		@if $medium == phone {
			@media only screen and (max-width: $break-small) { @content; }
		}
		@else if $medium == medium-screens {
			@media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
		}
		@else if $medium == wide-screens {
			@media only screen and (min-width: $break-large) { @content; }
		}
		@else if $medium == super-wide-screens {
			@media only screen and (min-width: $break-super-large) { @content; }
		}
	}
  }
