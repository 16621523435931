@import 'variables';
.d-hidden{
	display: none;
}

@include device('phone'){
	.d-phone-none{display:none;}
	.d-phone-block{display:block;}
}
@include device('medium-screens'){
	.d-medium-none{display:none;}
	.d-medium-block{display:block;}
}
@include device('wide-screens'){
	.d-wide-none{display:none;}
	.d-wide-block{display:block;}
}
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
	5: ($spacer * 3),
  ),
  $spacers
);
@each $name, $value in $spacers {
	.m-t-#{$name} { margin-top: $value !important; }
	.m-b-#{$name} { margin-bottom: $value !important;  }
	.m-l-#{$name} { margin-left: $value !important;  }
	.m-r-#{$name} { margin-right: $value !important;  }
	.p-t-#{$name} { padding-top: $value !important;  }
	.p-b-#{$name} { padding-bottom: $value !important;  }
	.p-l-#{$name} { padding-left: $value !important;  }
	.p-r-#{$name} { padding-right: $value !important;  }
}
.m-auto{margin:auto;}
.m-t-auto { margin-top: auto !important; }
.m-b-auto { margin-bottom: auto !important;  }
.m-l-auto { margin-left: auto !important;  }
.m-r-auto { margin-right: auto !important;  }
