header {
	&.jumbo {
		font-size: 52pt;
		line-height: 52pt;
		font-weight: bold;
	}
}

button:disabled,
button[disabled] {

	&.working {
		&:focus {
			outline: none;
		}
	}
	&.working:before {
		content: '' !important;
		//animation: pulsate 1s infinite;
		background-image: url('/assets/shared/icons/circle-spin.svg');
		background-repeat: no-repeat;
		animation: rotate 0.8s infinite linear;
		width: 16px;
		height: 16px;
		display: inline-block;
		position: absolute;
		margin-left: -24px;
		margin-right: 8px;
		margin-top: 4px;
	}
}

nav .logo img {
	height: 20px;
	display: block;
}

section {
	position: relative;
	padding: 30px 0;
	&.shaded {
		box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
	}
}

input::-webkit-autofill {
	-webkit-box-shadow: 0 0 0 100px white inset;
}

$break-small: 480px;
$break-large: 992px;

@mixin device($media) {
	@if $media == phone {
		@media only screen and (max-width: $break-small) { @content; }
	}
	@else if $media == medium-screens {
		@media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
	}
	@else if $media == wide-screens {
		@media only screen and (min-width: $break-large) { @content; }
	}
}

div.form-group {
	width: 100%;
	label {
		font-weight: 200;
		clear: both;
		margin-bottom: 2px;
		display: block;
		font-size: 12pt;
		font-weight: bold;
	}
	input[type='text'], select {
		outline: none;
		display: block;
		clear: left;
		border: 0;
		width: 100%;
		font-size: 13pt;
		font-weight: 400;
		border-radius: 4px;
		-webkit-appearance: none;
		//border-bottom: 1px solid $text;
		border: 1px solid #ccc;


		&:focus {
			border-color: #888;
		}
		&::-webkit-input-placeholder {
			color: #ccc;
		}
		&::-moz-placeholder {
			color: #ccc;
		}
		&::-ms-input-placeholder {
			color: #ccc;
		}
	}

	&.has-error {
		.help-block {
			color: red;
		}
	}
}

svg-icon svg {
	width: 100%;
}

.box.box-center {
	position: fixed;
	z-index: 3;
	top: 50%;
	left: 50%;
	width: 400px;
	padding: 20px;
	margin-top: -50px;
	transform: translate(-50%, -50%);
	border: none;
	box-shadow: 4px 4px 25px rgba(0,0,0,0.20);;
}
@include device(phone) {
	.box.box-center {
		position: initial;
		margin: 0;
		transform: none;
		width: 100%;

		box-shadow: none;
	}
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome and Opera */
  }

.vertical-center {
	position: fixed;
	z-index: 3;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table.table {
	thead td {
		padding: 20px;

		h3 {
			margin: 0;
		}
	}
	&.table-lines tfoot td {
		border-top: 1px solid #ddd;
		padding: 20px;
		p {
			margin: 0;
		}
	}
}

nav.nav-pane .logo img {
	height: 25px;
}

.dropdown-nav {
	&.dropdown-align-right {
		ul {
			position: absolute;
			margin-left: -135px;
		}
	}
	&.dropdown-align-center {
		ul {
			position: absolute;
			margin-left: -50%;
		}
	}
}

.spinner {
	display: inline-block;
	position: relative;
	height: 50px;
	width: 50px; }
	.spinner.spinner-small {
	  width: 24px;
	  height: 24px; }
	.spinner .spinner-left,
	.spinner .spinner-right {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 50%;
	  height: 100%;
	  overflow: hidden; }
	  .spinner .spinner-left:after,
	  .spinner .spinner-right:after {
		border: 2px solid #666;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 100;
		transition: border-color 100ms ease-out 0s; }
	.spinner .spinner-left:after {
	  transform-origin: right center;
	  transform: rotate(180deg);
	  border-radius: 100% 0 0 100% / 50% 0 0 50%;
	  border-right: none;
	  animation: spinner-left-rotation 1.6s ease-in infinite; }
	.spinner .spinner-right {
	  top: 0;
	  left: 49.4%;
	  height: 100%;
	  right: 0; }
	  .spinner .spinner-right:after {
		opacity: 0;
		transform: rotate(180deg);
		transform-origin: left center;
		border-left: none;
		border-radius: 0 100% 100% 0 / 0 50% 50% 0;
		animation: spinner-right-rotation 1.6s ease-out infinite; }
	.spinner.spinner-success .spinner-left:after,
	.spinner.spinner-success .spinner-right:after {
	  border-color: rgba(91, 181, 99, 1); }
	.spinner.spinner-danger .spinner-left:after,
	.spinner.spinner-danger .spinner-right:after {
	  border-color: rgba(224, 80, 67, 1); }
	.spinner.spinner-full .spinner-left:after {
	  animation: none;
	  transform: rotate(0deg); }
	.spinner.spinner-full .spinner-right:after {
	  animation: spinner-right-rotation-full 100ms ease-out;
	  animation-fill-mode: forwards;
	  opacity: 1; }
	.spinner.spinner-empty .spinner-left:after {
	  transform: rotate(0deg);
	  animation: spinner-left-rotation-empty 1.2s linear;
	  animation-fill-mode: forwards; }
	.spinner.spinner-empty .spinner-right:after {
	  transform: rotate(0deg);
	  animation: spinner-right-rotation-empty 1.2s ease-out;
	  animation-fill-mode: forwards; }

  @keyframes spinner-left-rotation {
	0% {
	  transform: rotate(180deg);
	  opacity: 1; }
	12.5% {
	  transform: rotate(0deg); }
	25% {
	  opacity: 1;
	  transform: rotate(0deg); }
	62.5% {
	  transform: rotate(-180deg);
	  opacity: 1; }
	62.51% {
	  opacity: 0; }
	100% {
	  opacity: 0;
	  transform: rotate(-180deg); } }

  @keyframes spinner-right-rotation {
	0% {
	  transform: rotate(180deg);
	  opacity: 0; }
	12.49% {
	  opacity: 0; }
	12.5% {
	  opacity: 1;
	  transform: rotate(180deg); }
	25% {
	  transform: rotate(0deg); }
	62.5% {
	  transform: rotate(0deg); }
	100% {
	  opacity: 1;
	  transform: rotate(-180deg); } }

  @keyframes spinner-right-rotation-full {
	100% {
	  transform: rotate(0deg); } }

  @keyframes spinner-left-rotation-empty {
	0% {
	  transform: rotate(0deg);
	  opacity: 1; }
	50% {
	  transform: rotate(-180deg);
	  opacity: 1; }
	50.01% {
	  opacity: 0; }
	100% {
	  opacity: 0;
	  transform: rotate(-180deg); } }

  @keyframes spinner-right-rotation-empty {
	0% {
	  transform: rotate(0deg);
	  opacity: 1; }
	50% {
	  transform: rotate(0deg);
	  opacity: 1; }
	99.99% {
	  opacity: 1; }
	100% {
	  opacity: 0;
	  transform: rotate(-180deg); } }

@keyframes pulsate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(0deg);
	}
	100% {
		opacity: 0.1;
	}
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.btn{
	border-radius: 25px/50%;
}
