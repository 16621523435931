@import 'general';
@import 'tables.scss';
@import 'bootstrap4.fills';
@import 'layout.scss';

.u-no-margin{
	margin:0;
}
.u-nowrap{
	white-space: nowrap;
}
.btn-secondary{
	border-width: 2px;
}
.numerical-summary{
	.row{
		& > *:nth-child(2)
		{
			text-align: right;
		}
		& > * + *{
			padding-right:0;
		}
		& > *:first-child{
			padding-left:0;
		}
		@include device('phone') {
			.s-align-left{
				text-align: left;
				padding-left:0;
			}
		}
		@include device('medium-screens') {
			.s-align-left{
				text-align: left;
				padding-left:0;
			}
		}
	}
}

body, html {
	margin: 0;
	padding: 0;

	font-size: 12pt;
	min-height: 100vh;
	font-family: 'Roboto';
	background-color: #fff;
}

input {
	-webkit-appearance: none;
}

select {
	background-color: #fff;
}

.kv-vertical {
	.key {
		margin-bottom: 10px;
		line-height: 1.1em;
	}
	.value {
		font-size: 28px;
		font-weight: 700;
		font-family: 'Qanelas', 'Roboto';
	}
	.value + .key {
		margin-top: 3px;
	}

}

.kv {
	display: flex;
	align-items: center;
	.key {
		flex-grow: 1;
		flex-shrink: 1;
		margin-right: 15px;
	}
	.value {
		flex-grow: 0;
		flex-shrink: 0;
	}
}
.kv, .kv-vertical {
	&.bold {
		.value {
			color: $color_primary;
		}
	}
	&.strong {
		.key, .value {
			font-weight: bold;
		}
	}
}
@include device('wide-screens'){
	.container{
		max-width: 1173px;
	}
}
@include device('medium-screens'){
	.container{
		padding: 0 32px;
	}
}
@include device('phone'){
	.container{
		padding: 0 32px;
	}
}
.empty-state{
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	width: 100%;
	min-height: 15.5em;
	border-radius: 4px;
}
[hidden]{
	display:none !important;
}
.semi-bold{
	font-weight: 600;
}

.theme-bambora {
	padding-top:48px;
	font-family: "Inter UI", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: rgba(30, 30, 30, 1);
	min-height: 100vh;
	background-color: #fafafa;
	.btn-secondary{
		border-width: 2px;
	}
	h1 {
		font-weight: 400;
	}
	h2, h3 {
		font-weight: 600;
	}
	h4, h5 {
		font-weight: 500;
	}
	.arrow-link{
		font-size: 16px;
		padding:0;
		position: relative;
		&:not(.btn){
			border-bottom:0 none;

		}
		&:before{
			content:"\00BB";
			display: inline-block;
			user-select: none;
			padding-right: 5px;
		}
		&:after{
			content:'';
			position: absolute;
			right:0;
			bottom:0;
			border-bottom-style: none;
			border-bottom-width: 1px;
			width:calc(100% - 14px);
		}
		&:hover:after{
			border-bottom-style: none;
		}
	}




	.track{
		background: rgba(#58d2b5, 0.5);
	}

	.btn, .btn[disabled]:after{
		border-radius:25px/50%;
		&.btn-small{
			border-radius:20px/50%;
		}
	}
	p{
		font-size:16px;
	}

	.font-apercu {
		font-family: Apercu, "Fakt Pro", sans-serif;
	}

	.font-alt {
		font-family: 'Inter UI', 'Fakt Pro', sans-serif;
	}

	.spinner-container {
		position: absolute;
		top: 300px;
	}

	.loader-message {
		position: absolute;
		top: 300px;
	}

}


.theme-partner {
	.partner-invisible {
		display: none !important;
	}
}

.box-padded {
	padding: 30px 20px;
}
.box-shaded {
	border: none;
	box-shadow: 4px 4px 25px rgba(0,0,0,0.20);;
}
.nowrap {
	white-space: nowrap;
}

ul.ticks {
	padding-left: 0;
	li {
		list-style: none;
		background: url(/assets/shared/icons/check_green.svg) no-repeat 0px 5px transparent;
		list-style-type: none;
		margin: 0;
		padding: 2px 0px 3px 32px;
		vertical-align: middle;
	}
}

@font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 100;
	src: url("/assets/partner/fonts/bambora/Inter-UI-Thin-BETA.woff2") format("woff2"),
		 url("/assets/partner/fonts/bambora/Inter-UI-Thin-BETA.woff") format("woff");
  }
  @font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/partner/fonts/bambora/Inter-UI-Regular.woff2") format("woff2"),
		 url("/assets/partner/fonts/bambora/Inter-UI-Regular.woff") format("woff");
  }

  @font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 500;
	src: url("/assets/partner/fonts/bambora/Inter-UI-Medium.woff2") format("woff2"),
		 url("/assets/partner/fonts/bambora/Inter-UI-Medium.woff") format("woff");
  }

  @font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/partner/fonts/bambora/Inter-UI-Bold.woff2") format("woff2"),
		 url("/assets/partner/fonts/bambora/Inter-UI-Bold.woff") format("woff");
  }

  @font-face {
	font-family: 'Inter UI';
	font-style:  normal;
	font-weight: 900;
	src: url("/assets/partner/fonts/bambora/Inter-UI-Black.woff2") format("woff2"),
		 url("/assets/partner/fonts/bambora/Inter-UI-Black.woff") format("woff");
  }

$break-small: 480px;
$break-large: 992px;

@mixin device($media) {
	@if $media == phone {
		@media only screen and (max-width: $break-small) { @content; }
	}
	@else if $media == medium-screens {
		@media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
	}
	@else if $media == wide-screens {
		@media only screen and (min-width: $break-large) { @content; }
	}
}

@include device('phone'){
	.d-phone-none{display:none;}
	.d-phone-block{display:block;}
}
@include device('medium-screens'){
	.d-medium-none{display:none;}
	.d-medium-block{display:block;}
}
@include device('wide-screens'){
	.d-wide-none{display:none;}
	.d-wide-block{display:block;}
}
