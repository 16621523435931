section {
	.content-wrapper {
		width: 80%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		&.content-wrapper-narrow {
			max-width: 800px;
		}
	}
	.section-intro {
		text-align: center;
		h1 {
			margin: 0;
		}
		p {
			margin-top: 20px;
		}
	}
}

@media only screen and (max-width: 600px) {
	section {
		.content-wrapper {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.btn-rounded {
	border-radius: 100px;
}