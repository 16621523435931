.table-like{
	.row{
		margin:0;
		&[class^="border-"], &[class*=" border-"]{
			border-width: 0;
		}
		& > *{padding:10px;}
	}
	&.table-like-underline{
		.row{
			&[class^="border-"], &[class*=" border-"]{
				& > *{
					border-bottom-style: solid;
					border-bottom-width: 1px;
					border-color: inherit;
				}
			}
		}
	}
}

.new-table{
	&-m-fluid{
		width:100%;
	}

	&-row{
		&:last-child{
			border-bottom-style: solid;
			border-bottom-width: 1px;
		}
		min-height:66px;
		border-top-style: solid;
		border-top-width: 1px;
		border-radius: 4px;

		&:hover {
			&:last-child{
				border-bottom-color:transparent;
			}
			box-shadow: 0px 0px 14px 0px rgba(162, 162, 162, 0.2574);
			border-top-color:transparent;
    		border-radius: 5px;
			& .new-table-cell {
				$rowProrutionLength: 20;
				$shadowSize:14;

			}
			& + .new-table-row{
				border-top-color:transparent;
			}
		}
	}
	&-cell{
		text-align:left;
		padding:15px 0;
		position: relative;
		$row-vertical-padding: 14px;
		&:first-child{
			padding-left:$row-vertical-padding;
		}
		&:last-child{
			padding-right:$row-vertical-padding;
		}
	}
	&-header{
		font-size: 12px;
		line-height: 24px;
		font-weight: normal;
		padding:0;
		margin:0;
		vertical-align: bottom;
	}
	/*&-row-m-header{
		border-bottom-style: solid;
		border-bottom-width: 1px;
	}*/
}
